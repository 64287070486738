<template>
    <div class="w-screen h-screen font-['proxima-nova']" v-if="login">
        <section class="bg-gray-50 dark:bg-gray-900">
          <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
              <div class="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
                  <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
                      <h1 class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                          Enter Password
                      </h1>
                      <div class="space-y-4 md:space-y-6" action="#">
                          <div>
                              <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-left">Password</label>
                              <input type="password" name="password" id="password" v-model="password" placeholder="••••••••" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required="">
                          </div>
                          <button @click="loginWithPassword()" class="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-blue-500 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Sign in</button>
                      </div>
                  </div>
              </div>
          </div>
        </section>
    </div>
    <div class="w-screen h-screen font-['proxima-nova']" v-if="error">
        <section class="bg-gray-50 dark:bg-gray-900">
          <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
              <div class="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
                  <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
                      <h1 class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                          Oops!
                      </h1>
                      <div class="space-y-4 md:space-y-6" action="#">
                        <p class="text-gray-500 mt-2 text-base">You don't have access to this form! Looks like this link isn't working or it's already been used. Please get in touch with the person who sent you this link for further assistance.</p>
                      </div>
                  </div>
              </div>
          </div>
        </section>
    </div>
    <div class="w-screen h-screen grid grid-cols-1 text-center md:grid-cols-10 py-32 md:py-32 overflow-y-scroll font-['proxima-nova']" :style="'background-image: url(' + form.background_url + '); background-size: cover;'" v-if="form">

        <div class="col-span-3"></div>
        <div class="col-span-4" v-if="!success">
            <div class="h-auto bg-white rounded-md border border-gray-100  mx-2  mb-4 text-center relative" v-if="team == 'adobe'">
                <img class="w-full rounded-sm" src="/img/GSO_banner.png" />
            </div>

            <div v-if="team == 'adobe'" class="h-auto bg-white rounded-md border border-gray-100 py-12  px-6 md:px-8 md:py-18 pb-12 mx-2   text-center relative">

                <span class="outline-none w-full center font-extrabold tracking-tight text-2xl md:text-2xl border-0 inline text-center mt-2" role="textbox" >{{form.title}}</span>

                <p class="outline-none w-full center font-normal text-base mt-4 border-0 block text-center">{{form.description}}</p>

            </div>

            <div v-if="team != 'adobe'" class="h-auto bg-white rounded-md border border-gray-100 py-24  px-6 md:px-8 md:py-18 pb-12 mx-2   text-center relative">

                <div class="h-36 w-36 rounded-full absolute margin-auto bg-red-800 left-1/2 transform -translate-x-1/2 -top-20 border-8 border-solid border-white" :style="'background-image: url(' + form.logo_url + '); background-size: cover;'"></div>
                <span class="outline-none w-full center font-extrabold tracking-tight text-2xl md:text-2xl border-0 inline text-center mt-2" role="textbox" >{{form.title}}</span>

                <p class="outline-none w-full center font-normal text-base mt-4 border-0 block text-center">{{form.description}}</p>

            </div>



            <div class="h-auto bg-white rounded-md border border-gray-100 py-12  px-6 md:px-8 xl:  pb-12 mx-2   text-center relative mb-4
             mt-4" v-if="form.redeem_type == 'fixed' & filteredItems.length > 0">
                <div class="outline-none w-full mt-2 mb-10">
                    <h2 class="text-center font-extrabold tracking-tight text-2xl">Select sizes / variations</h2>
                    <p class="mt-2 text-gray-600  px-8">Please select your desired sizes for the following items.</p>
                </div>

                <div class="grid grid-cols-2 md:grid-cols-3 gap-4 mt-8">
                    <div v-for="f in filteredItems" v-bind:key="f.stock_id">
                        <div class="p-4 border border-grey-50 rounded-sm text-center" v-if="f.items.length > 1">
                        <div>
                        <img :src="'https://swagsum.ams3.cdn.digitaloceanspaces.com/' + f.image_url" class="w-full" />
                        <label class="block font-bold text-md text-black mb-2 text-center" for="title"> {{f.title}} </label>
                        <div v-if="f.items">
                        <select v-model="f.selected_variation_id" class="mb-4 py-3 px-3 pr-11 block w-full border-gray-200 text-sm rounded-sm focus:border-blue-500 focus:ring-blue-500 bg-slate-50" name="item" >
                               <option v-for="v in f.items" :value="v.id" v-bind:key="v.id">{{v.title}}</option>
                           </select>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>


             <div class="h-auto bg-white rounded-sm border border-gray-100 py-12  px-6 md:px-8  pb-12 mx-2   text-center relative mb-4 mt-4" v-if="form.redeem_type == 'select'">
                 <div class="outline-none w-full mt-2 mb-10">
                     <h2 class="text-center font-extrabold tracking-tight text-2xl">Choose your gift or donation</h2>
                     <p class="mt-2 text-gray-600  px-8">Please select from the following items.</p>
                 </div>

                 <div class="grid grid-cols-2 md:grid-cols-2 gap-4 mt-8">
                     <div v-for="f in data.items" :key="f.stock_id"
                          class="p-4 border-2 rounded-sm cursor-pointer"
                          :class="{ 'border-blue-500': f.isSelected, 'border-grey-50': !f.isSelected }"
                          @click="selectCard(f)">

                         <img :src="'https://swagsum.ams3.cdn.digitaloceanspaces.com/' + f.image_url" class="w-full" />
                         <label class="block font-bold text-md text-black mb-2 text-left mt-2">{{ f.title }}</label>

                         <div v-if="f.items.length > 0">
                             <select v-model="f.selected_variation_id"
                                     class="mb-4 py-3 px-3 pr-11 block w-full border-gray-200 text-sm rounded-md focus:border-blue-500 focus:ring-blue-500 bg-slate-50"
                                     name="item">
                                 <option v-for="v in f.items" :value="v.id" :key="v.id">{{ v.title }}</option>
                             </select>
                         </div>
                     </div>

                 </div>
             </div>

            <div class="h-auto bg-white rounded-md border border-gray-100 py-12  px-6 md:px-8 xl:  pb-12 mx-2   text-center relative mt-4">
                <div class="outline-none w-full mt-2 mb-10">
                    <h2 class="text-center font-extrabold tracking-tight text-xl md:text-2xl">Your shipping details</h2>
                    <p class="mt-2 text-gray-600  px-8">Please ensure you fill in the <b class="text-black">correct</b> address for shipping. It's crucial for making sure your swag package arrives exactly where it should.</p>
                </div>

                <div class="grid grid-cols-2 gap-4 mt-8">
                    <div>
                        <label class="block font-bold text-base text-black mb-2 text-left" for="title"> Country </label>
                         <select class=" mb-4 py-3 px-3 pr-11 block w-full border-gray-200 text-sm rounded-sm focus:border-blue-500 focus:ring-blue-500 bg-slate-50" name="country" v-model="data.country" >
                               <option value=""> - Country - </option>
                               <option value="AX">Aland Islands</option>
                               <option value="AL">Albania</option>
                               <option value="AD">Andorra</option>
                               <option value="AT">Austria</option>
                               <option value="AU">Australia</option>
                               <option value="BR">Brazil</option>
                               <option value="BY">Belarus</option>
                               <option value="BE">Belgium</option>
                               <option value="BA">Bosnia and Herzegovina</option>
                               <option value="BG">Bulgaria</option>
                               <option value="HR">Croatia</option>
                               <option value="CZ">Czech Republic</option>
                               <option value="DK">Denmark</option>
                               <option value="EE">Estonia</option>
                               <option value="FO">Faroe Islands</option>
                               <option value="FI">Finland</option>
                               <option value="FR">France</option>
                               <option value="DE">Germany</option>
                               <option value="GI">Gibraltar</option>
                               <option value="GR">Greece</option>
                               <option value="GG">Guernsey</option>
                               <option value="VA">Holy See (Vatican City State)</option>
                               <option value="HU">Hungary</option>
                               <option value="IS">Iceland</option>
                               <option value="IE">Ireland</option>
                               <option value="IM">Isle of Man</option>
                               <option value="IT">Italy</option>
                               <option value="IN">India</option>
                               <option value="JE">Jersey</option>
                               <option value="XK">Kosovo</option>
                               <option value="LV">Latvia</option>
                               <option value="LI">Liechtenstein</option>
                               <option value="LT">Lithuania</option>
                               <option value="LU">Luxembourg</option>
                               <option value="MK">Macedonia, the Former Yugoslav Republic of</option>
                               <option value="MT">Malta</option>
                               <option value="MD">Moldova, Republic of</option>
                               <option value="MC">Monaco</option>
                               <option value="ME">Montenegro</option>
                               <option value="NL">Netherlands</option>
                               <option value="NO">Norway</option>
                               <option value="PH">Philippines</option>
                               <option value="PL">Poland</option>
                               <option value="PT">Portugal</option>
                               <option value="RO">Romania</option>
                               <option value="SM">San Marino</option>
                               <option value="RS">Serbia</option>
                               <option value="CS">Serbia and Montenegro</option>
                               <option value="SG">Singapore</option>
                               <option value="SK">Slovakia</option>
                               <option value="SI">Slovenia</option>
                               <option value="ES">Spain</option>
                               <option value="SJ">Svalbard and Jan Mayen</option>
                               <option value="SE">Sweden</option>
                               <option value="CH">Switzerland</option>
                               <option value="GB">United Kingdom</option>
                               <option value="US">United States</option>
                           </select>
                    </div>
                    <div>
                        <label class="block font-bold text-base text-black mb-2 text-left" for="title"> Postal code</label>
                         <input type="text" class=" mb-4 py-3 px-3 pr-11 block w-full border-gray-200   text-sm rounded-sm focus:border-blue-500 focus:ring-blue-500 bg-slate-50" placeholder="Postal code"  v-model="data.postalCode">
                    </div>
                </div>

                <div class="grid grid-cols-2">
                    <div class="col-span-2">
                        <label class="block font-bold text-base text-black mb-2 text-left" for="title"> Address line 1</label>
                         <input type="text" class=" mb-4 py-3 px-3 pr-11 block w-full border-gray-200   text-sm rounded-sm focus:border-blue-500 focus:ring-blue-500 bg-slate-50" placeholder="Address line 1" v-model="data.address1">
                    </div>
                    <div class="col-span-2">
                        <label class="block font-bold text-base text-black mb-2 text-left" for="title"> Address line 2
                        </label>
                        <input type="text"  class=" mb-4 py-3 px-3 pr-11 block w-full border-gray-200   text-sm rounded-sm focus:border-blue-500 focus:ring-blue-500 bg-slate-50" placeholder="Address line 2" v-model="data.address2">
                    </div>
                </div>
                <div class="grid grid-cols-2 gap-4">
                    <div class="col-span-1">
                        <label class="block font-bold text-base text-black mb-2 text-left" for="title"> State</label>
                         <input type="text" class=" mb-4 py-3 px-3 pr-11 block w-full border-gray-200   text-sm rounded-sm focus:border-blue-500 focus:ring-blue-500 bg-slate-50" placeholder="State" v-model="data.state">
                    </div>
                    <div class="col-span-1">
                        <label class="block font-bold text-base text-black mb-2 text-left" for="title"> City
                        </label>
                        <input type="text"  class="mb-4 py-3 px-3 pr-11 block w-full border-gray-200   text-sm rounded-sm focus:border-blue-500 focus:ring-blue-500 bg-slate-50" placeholder="City"  v-model="data.city">
                    </div>
                </div>

            </div>

   <div class="h-auto bg-white rounded-md border border-gray-100 py-12  px-6 md:px-8 xl:  pb-12 mx-2   text-center relative mt-4">
                <div class="outline-none w-full mt-2 mb-10">
                    <h2 class="text-center font-extrabold tracking-tight text-xl md:text-2xl">Your contact details</h2>
                    <p class="mt-2 text-gray-600  px-8">To get your swag on its way, please provide your full name, email, and phone number. This helps us keep you posted about your package's journey!</p>
                </div>

                <div class="grid grid-cols-2 gap-4 mt-8">
                    <div>
                        <label class="block font-bold text-base text-black mb-2 text-left" for="title"> First name </label>
                         <input type="text"  class="mb-4 py-3 px-3 pr-11 block w-full border-gray-200 text-sm rounded-sm focus:border-blue-500 focus:ring-blue-500 bg-slate-50" placeholder="First name" v-model="data.firstName">
                    </div>
                    <div>
                        <label class="block font-bold text-base text-black mb-2 text-left" for="title"> Last name</label>
                         <input type="text" class=" mb-4 py-3 px-3 pr-11 block w-full border-gray-200   text-sm rounded-sm focus:border-blue-500 focus:ring-blue-500 bg-slate-50" placeholder="Last name" v-model="data.lastName" >
                    </div>
                </div>

                <div class="grid grid-cols-2">
                    <div class="col-span-2">
                        <label class="block font-bold text-base text-black mb-2 text-left" for="title"> Email address</label>
                         <input type="text" class=" mb-4 py-3 px-3 pr-11 block w-full border-gray-200   text-sm rounded-sm focus:border-blue-500 focus:ring-blue-500 bg-slate-50" placeholder="you@example.com" v-model="data.email">
                    </div>
                    <div class="col-span-2">
                        <label class="block font-bold text-base text-black mb-2 text-left" for="title"> Phone number</label>
                         <input type="text"  class=" mb-4 py-3 px-3 pr-11 block w-full border-gray-200   text-sm rounded-sm focus:border-blue-500 focus:ring-blue-500 bg-slate-50" placeholder="+00 0 00 00000" v-model="data.phone" >
                    </div>
                </div>

            </div>

            <div class="h-auto bg-white rounded-md border border-gray-100 py-12  px-6 md:px-8 xl:  pb-12 mx-2   text-center relative mb-4
             mt-4">
                <div class="text-red-600 block" v-for="error in errors" v-bind:key="error">
                    {{error}}
                </div>
                <button
                 type="button" @click="submitForm()" class="py-3 px-6 inline-flex justify-center items-center gap-2 rounded-sm border border-transparent font-semibold text-white block font-bold text-base text-black text-left" :style="'background-color: ' + form.primary_color + ';' "> Submit </button>
            </div>
            <a href="https://swagsum.com"><div class="flex items-center justify-center p-4 text-white">
                Powered by<br />
                <img src="/img/swagsum.png" class="w-32 ml-2 block" />
            </div>
            </a>


        </div>
        <div class="col-span-4" v-if="success">
            <div class="h-auto bg-white rounded-md border border-gray-100 py-18  px-6 md:px-8 pt-20 pb-12 mx-2   text-center relative">
                <div class="h-36 w-36 rounded-full absolute margin-auto bg-red-800 left-1/2 transform -translate-x-1/2 -top-20 border-8 border-solid border-white" :style="'background-image: url(' + form.logo_url + '); background-size: cover;'"></div>
                <span class="outline-none w-full center font-extrabold text-3xl border-0 inline text-center mt-2 pt-12 mt-10" role="textbox" >That's it - Your swag is on it's way!</span>

                <p class="outline-none w-full center font-normal text-base mt-4 border-0 block text-center" role="textbox">Thank you for filling out this form. Your swag is on its way to you!</p>

            </div>
        </div>
        <div class="col-span-3"></div>

    </div>
      <div class="w-screen h-screen font-['proxima-nova']" v-if="form && !form.isPublished">
            <section class="bg-gray-50 dark:bg-gray-900">
              <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
                  <div class="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
                      <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
                          <h1 class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                              Oops!
                          </h1>
                          <div class="space-y-4 md:space-y-6" action="#">
                            <p class="text-gray-500 mt-2 text-base">This form is not accessible at this time! Please get in touch with the person who sent you this link for further assistance.</p>
                          </div>
                      </div>
                  </div>
              </div>
            </section>
        </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'FormView',
  computed: {
    team() {
      return this.$route.params.team;
    },
    slug() {
      return this.$route.params.slug;
    },
    url_token() {
      return this.$route.params.url_token;
    },
    filteredItems() {
        return this.data.items.filter(item => item.items.length > 1);
    }
  },
  data() {
      return {
        form: null,
        login: false,
        password: null,
        errors: [],
        error: false,
        success: false,
        maxSelectable: 1,
        data: {
           firstName: '',
           lastName: '',
           phone: '',
           address1: '',
           postalCode: '',
           city: '',
           state: '',
           country: '',
           email: '',
           password: '',
           items: [],
           }
      }
    },
    methods: {


        selectCard(item) {
            if (item.isSelected) {
                // If the item is already selected, deselect it
                item.isSelected = false;
            } else {
                // Count the currently selected items
                const selectedCount = this.data.items.filter(i => i.isSelected).length;

                // Only select a new item if it does not exceed the limit
                if (selectedCount < this.maxSelectable) {
                    item.isSelected = true;
                } else {
                    // Optionally, you can notify the user that the limit has been reached
                    alert(`You can only select up to ${this.maxSelectable} items.`);
                }
            }
        },
        loginWithPassword() {
            axios.post('https://app.swagsum.com/api/public/forms/' + this.team + '/' + this.slug, {
                password: this.password
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            .then((response) => {
              this.form = response.data;
              this.data.password = this.password;
              var self = this;
              setTimeout(function () {
                  self.login = false;
              }, 1000);
              })
            .catch((error) => {

                if (error.response.status == 401 && this.login == true) {
                     this.errors = ['Invalid password'];
                  }
                 else if (error.response.status == 401 && this.login == false) {
                    this.login = true;
                 }
            })
        },
        submitForm() {
            this.errors = [];
            //Validation

            if(this.url_token){
                this.data.url_token = this.url_token;
            }
            if (this.data.firstName.length <= 2) {
                    this.errors.push('Please fill out your first name. It must have at least 3 characters.');
                    return;
                  }

                  if (this.data.lastName.length <= 3) {
                    this.errors.push('Please fill out your last name. It must have at least 3 characters.');
                    return;
                  }

                  if (this.data.phone.length <= 11) {
                    this.errors.push('Mobile phone number must have at least 11 characters.');
                    return;
                  }

                  if (this.data.address1.length <= 5) {
                    this.errors.push('Address must have at least 5 characters.');
                    return;
                  }

                  if (this.data.postalCode.length <= 1) {
                    this.errors.push('Postal code must have at least 2 characters.');
                    return;
                  }

                  if (this.data.city.length <= 1) {
                    this.errors.push('Please fill in your city.');
                    return;
                  }

                  if (this.data.state.length <= 1) {
                    this.errors.push('Please fill in your state.');
                    return;
                  }

                  // Email validation
                  if (!this.validateEmail(this.data.email)) {
                    this.errors.push('Please enter a valid email address.');

                    return;
                    }

                    axios.post('https://app.swagsum.com/api/public/forms/' + this.team + '/' + this.slug + "/submit", this.data,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                        }
                    })
                    .then((response) => {
                        console.log(response);
                        this.success = true;
                      })
                    .catch((error) => {
                        console.log(error);

                    })

        },
        validateEmail(email) {
          const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
          return re.test(email);
        }
    },
  mounted() {
      if (this.team && this.slug) {
      var url = 'https://app.swagsum.com/api/public/forms/' + this.team + '/' + this.slug;
      if(this.url_token){
        url = 'https://app.swagsum.com/api/public/forms/' + this.team + '/' + this.slug + '/'+ this.url_token;
      }
        axios.post(url)
        .then((response) => {
            console.log("Response data:", response.data); // Check the structure of response.data
            this.form = response.data;
            var selectedItems = [];

            this.form.items.forEach((i) => {
                console.log("Current item:", i); // Check each item
                if (i.items && i.items.length > 0) { // Safeguard against undefined or empty items array
                    selectedItems.push({
                        stock_id: i.id,
                        selected_variation_id: i.items[0].id,
                        title: i.title,
                        image_url: i.image_url,
                        items: i.items,
                        isSelected: false,
                    });
                }
            });

            this.data.items = selectedItems;
        })
        .catch((error) => {
            if (error.response.status == 401 && this.login == true) {
             this.errors = ['Invalid password'];

            }else if (error.response.status == 401 && this.login == false) {
                this.login = true;
             }
             else {
                 this.error = true;
             }
        });


    }else {
          this.error = true;
      }
  }
};
</script>

<style>
/* Add any specific styles for your form view here */
body {
    font-family: proxima-nova, sans-serif;
}
</style>
